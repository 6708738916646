import React from 'react'

export default function XClose() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="16.4048"
        y="5.09125"
        width="1.6"
        height="16"
        rx="0.8"
        transform="rotate(45 16.4048 5.09125)"
        fill="#404040"
      />
      <rect
        x="17.5359"
        y="16.4049"
        width="1.6"
        height="16"
        rx="0.8"
        transform="rotate(135 17.5359 16.4049)"
        fill="#404040"
      />
    </svg>
  )
}
