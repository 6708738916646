import React from 'react'
import cn from 'classnames'

import Gutter from '../Gutter'
import Heading from '../Type/Heading'
import Body from '../Type/Body'
import A from '../Type/A'

import { IconRowSectionProps } from './IconRowSectionTypes'
import PlusIcon from './icons/Plus'
import BoxIcon from './icons/Box'
import MoneyIcon from './icons/Money'

const hideMobileShowDesktop = `
  hidden
  lg:block
`
const showMobileHideDesktop = `
  block
  lg:hidden
`

const inlineHideMobileShowDesktop = `
  hidden
  lg:inline
`
const inlineShowMobileHideDesktop = `
  inline
  lg:hidden
`
const Icons = {
  plus: PlusIcon,
  box: BoxIcon,
  money: MoneyIcon
}
export default function IconRowSection({
  headline,
  copy,
  mobileHeadline,
  mobileCopy,
  video = '',
  cards
}: IconRowSectionProps) {
  return (
    <section aria-labelledby="iconRowHeadline">
      <Gutter>
        <div className="flex flex-col text-center mt-12 pb-8 lg:pb-20">
          <Heading as="h2" variant="h2">
            <div id="iconRowHeadline">
              <span className={cn(showMobileHideDesktop)}>
                {mobileHeadline}
              </span>
              <span className={cn(hideMobileShowDesktop)}>{headline}</span>
            </div>
          </Heading>

          {mobileCopy || copy ? (
            <div className="mt-4">
              <span className={cn(inlineShowMobileHideDesktop)}>
                {mobileCopy}
              </span>
              <span className={cn(inlineHideMobileShowDesktop)}>{copy}</span>
              &nbsp;
            </div>
          ) : null}

          <div className="grid grid-cols-1 lg:grid-cols-3 mt-11">
            {cards.map((card, index) => {
              const Icon = Icons[card.icon]
              return (
                <div
                  key={`iconRowSectionIcon-${index}`}
                  className="mt-7 lg:mt-0"
                >
                  <Icon />
                  <Heading as="h4" variant="h4">
                    <span className={cn(showMobileHideDesktop)}>
                      {card.mobileHeadline}
                    </span>
                    <span className={cn(hideMobileShowDesktop)}>
                      {card.headline}
                    </span>
                  </Heading>
                  <Body variant="body-small">
                    <span className={cn(showMobileHideDesktop)}>
                      {card.mobileCopy}
                    </span>
                    <span className={cn(hideMobileShowDesktop)}>
                      {card.copy}
                    </span>
                  </Body>
                </div>
              )
            })}
          </div>
        </div>
      </Gutter>
    </section>
  )
}
