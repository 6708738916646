import React from 'react'

export default function CircledNumberOne() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.501" r="12" fill="#196ECF" />
      <path
        d="M13.3706 7.55812V17.3581H11.1026V9.37812H9.14263V7.55812H13.3706Z"
        fill="white"
      />
    </svg>
  )
}
