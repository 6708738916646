import React from 'react'
import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'

import FormScreens from './pages/FormScreens'
import SuccessPage from './pages/Success'
import ErrorPage from './pages/Error'

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<FormScreens />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<ErrorPage />} />
    </ReactRouterRoutes>
  )
}
