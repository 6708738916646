import React from 'react'
import cn from 'classnames'

import Body from '../Type/Body'

import './styles.css'

const WrapperStyles = `
  m-auto
  bg-mono-100
`

const ExplanatoryCopySection = () => {
  return (
    <div className="mx-6 mb-9">
      <div className={cn(WrapperStyles, 'SkipForNowCopy')}>
        <Body variant="body-small" className="p-6">
          If you &ldquo;
          <span className="text-primary font-bold">Skip for Now</span>
          ”, you can still complete your enrollment and we will reach out to you
          to gather more information.
        </Body>
      </div>
    </div>
  )
}

export default ExplanatoryCopySection
