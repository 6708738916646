import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './styles/main.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { critical } from './utils/logs'
import { getAppEnvironment, isProduction, isPreprod } from './utils/environment'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData } from '@sentry/integrations'

// PreProd + Prod setup
if (isPreprod() || isProduction()) {
  // Set up Sentry for error reporting.
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: getAppEnvironment(),
    integrations: [new Dedupe(), new ExtraErrorData()],
    attachStacktrace: true,
    release: process.env.RELEASE_DATE
  })
}

if (
  process.env.REACT_APP_DATADOG_APP_ID &&
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN
) {
  const commonConfig = {
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    env: getAppEnvironment(),
    service: 'customer-enrollment-web',
    site: 'datadoghq.com'
  }
  datadogRum.init({
    ...commonConfig,
    trackInteractions: false
  })
  datadogLogs.init(commonConfig)
} else {
  critical(
    'Could not load Datadog RUM because REACT_APP_DATADOG_APP_ID and REACT_APP_DATADOG_CLIENT_TOKEN environment variables are not present.'
  )
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
