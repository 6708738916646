/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, forwardRef, useState } from 'react'
import { IMaskInput } from 'react-imask'
import { FieldError } from 'react-hook-form'

import TextField from './TextField'
import '../styles/styles.css'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  mask: string
  placeholder: string
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask={props.mask}
        definitions={{
          '#': /[1-9]/
        }}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
        placeholder={props.placeholder}
      />
    )
  }
)

interface NumberMaskedFieldProps {
  name: string
  label: string
  onChange: Function
  invalid: boolean
  error: FieldError | null | undefined
  mask: string
  placeholder: string
  defaultValue?: string | null | Date | {}
  dataID: string
}

export default function NumberMaskedField({
  name,
  label,
  onChange,
  invalid,
  error,
  mask,
  placeholder,
  defaultValue = '',
  dataID
}: NumberMaskedFieldProps) {
  const [value, setValue] = useState<string | null | Date | {}>(defaultValue)

  // determine if we need to set the shrink to false for the summary page
  const shrinkAttr =
    value !== '' && value !== null && typeof value !== 'object'
      ? {
          shrink: true
        }
      : {}

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
    setValue(event.target.value)
  }

  const helperText =
    typeof error === 'object' && error !== null
      ? 'message' in error
        ? error.message
        : ''
      : ''

  return (
    <div>
      <TextField
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        InputProps={{
          inputComponent: TextMaskCustom as any,
          inputProps: { name, mask, placeholder }
        }}
        error={invalid}
        helperText={helperText}
        InputLabelProps={shrinkAttr}
        className="ShortFieldLength"
        dataID={dataID}
      />
    </div>
  )
}
