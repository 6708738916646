/* eslint-disable react/jsx-no-target-blank */
import React, { ReactNode } from 'react'
import cn from 'classnames'

import A from '../Type/A'
import Body from '../Type/Body'

import LogoNABP from './LogoNABP'
import { data, logoData } from './data'
import './footer.css'

export interface FooterLink {
  text: string
  url: string
}
export interface FooterProps {
  phoneMobile?: 'active' | 'inactive'
  links?: FooterLink[]
  logoLink?: FooterLink
}

const wrapperFooterLinkStyles = `
  m-auto
  px-6
  py-4
  md:py-10
`

const footerGridStyles = `
  m-auto
  grid

  grid-flow-col

  grid-cols-2
  grid-rows-6

  md:grid-cols-4
  md:grid-rows-4

  lg:grid-cols-5
  lg:grid-rows-3

`

const footerLogoStyles = `
  m-auto
  inline-block
  self-center

  col-start-1
  col-end-3
  row-start-7

  md:col-span-4
  md:row-span-full

  lg:col-span-5
  lg:row-span-full
`

const mobilePhoneBannerDiv = {
  active: 'block lg:hidden',
  inactive: 'hidden'
}

export default function Footer({
  phoneMobile = 'inactive',
  links = data,
  logoLink = logoData
}: FooterProps) {
  const d = new Date()
  const year = d.getFullYear()
  return (
    <footer className="bg-mono-200">
      <div className={cn(wrapperFooterLinkStyles, 'wrapper-footerLinks')}>
        <ul className={cn(footerGridStyles, 'footerLinks')}>
          {links.map((item, key) => (
            <li className="px-2 py-4" key={`footer-item-${key}`}>
              <A url={item.url}>{item.text}</A>
            </li>
          ))}
          <li className={footerLogoStyles}>
            <a
              href={logoLink.url}
              title={logoLink.text}
              target="_blank"
              className={cn('footerLogo')}
            >
              <LogoNABP />
            </a>
          </li>
        </ul>
      </div>
      <div className="bg-primary text-center py-4">
        <Body variant="label-small-white">
          &copy; {year} divvyDOSE | All Rights Reserved
        </Body>
      </div>
      <div
        className={cn(
          'mobilePhoneBannerDivHeight',
          mobilePhoneBannerDiv[phoneMobile]
        )}
      ></div>
    </footer>
  )
}
