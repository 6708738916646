import axios from 'axios'
import { format } from 'date-fns'

import {
  ContactProps,
  InsuranceProps,
  PromoProps
} from '../../../routes/pages/FormScreens/FormScreenTypes'
import { info, error, debug } from '../../../utils/logs'

type SummaryProps = ContactProps & InsuranceProps & PromoProps

const DATA_LAYER_URL = process.env.REACT_APP_DATA_LAYER_URL

export default async function formatDataAndPostRequest(formData: SummaryProps) {
  const formattedData = formatFormData(formData)
  // Debug, not logged in datadog or in production console output
  debug('Formatted data', formattedData)

  await axios
    .post(`${DATA_LAYER_URL}`, formattedData)
    .then((res) => {
      info('Enrollment API success response:', {
        message: res.data,
        statusCode: res.status
      })
    })
    .catch((error_res) => {
      error('Enrollment API error response:', error_res.response)
      throw new Error(error_res.message)
    })
}

export function formatFormData(data: SummaryProps) {
  let finalPayload
  let insurance = {}

  finalPayload = {
    firstName: data.firstName,
    lastName: data.lastName,
    dateOfBirth: data.dateOfBirth ? format(data.dateOfBirth, 'yyyy-MM-dd') : '',
    phoneNumbers: [{ phoneNumber: data.phoneNumber }]
  }

  if (data.email !== '') {
    finalPayload = {
      ...finalPayload,
      email: data.email
    }
  }

  if (data.promoCode !== '') {
    finalPayload = {
      ...finalPayload,
      promoCodeOverride: data.promoCode
    }
  }

  // no insurance information is provided
  if (
    data.providerName === '' &&
    data.idNumber === '' &&
    data.groupNumber === '' &&
    data.rxPCN === '' &&
    data.rxBIN === ''
  ) {
    debug('Insurance not provided', data)
  }
  // insurance information is provided, check data for empty strings
  else {
    if (data.providerName !== '') {
      insurance = {
        ...insurance,
        providerName: data.providerName
      }
    }

    if (data.idNumber !== '') {
      insurance = {
        ...insurance,
        idNumber: data.idNumber
      }
    }

    if (data.groupNumber !== '') {
      insurance = {
        ...insurance,
        groupNumber: data.groupNumber
      }
    }

    if (data.rxPCN !== '') {
      insurance = {
        ...insurance,
        rxpcn: data.rxPCN
      }
    }

    if (data.rxBIN !== '') {
      insurance = {
        ...insurance,
        rxbin: data.rxBIN
      }
    }

    finalPayload = {
      ...finalPayload,
      clinicalProfile: {
        insurances: [insurance]
      }
    }
  }

  return finalPayload
}
