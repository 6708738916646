import React from 'react'

const ActiveStep = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
    <circle
      cx="9"
      cy="9"
      r="8.25"
      fill="#fff"
      stroke="#177C8F"
      strokeWidth="1.5"
      transform="rotate(-90 9 9)"
    />
  </svg>
)

export default ActiveStep
