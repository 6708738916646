import React from 'react'

export default function CircledNumberFour() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.501" r="12" fill="#196ECF" />
      <path
        d="M16.4796 15.3001H14.8836V17.3581H12.6716V15.3001H7.39361V13.7741L12.0136 7.55812H14.3936L10.1096 13.4521H12.7416V11.6181H14.8836V13.4521H16.4796V15.3001Z"
        fill="white"
      />
    </svg>
  )
}
