import React from 'react'
import { FormScreenProps } from '../FormScreenTypes'

import HeroSection from '../../../../components/Hero'
import PhoneCTADesktop from '../../../../components/PhoneCTADesktop'
import MobileFormStepper from '../../../../components/FormStepper/MobileFormStepper'
import DesktopFormStepper from '../../../../components/FormStepper/DesktopFormStepper'
import InsuranceForm from '../../../../components/Form/Insurance'
import ExplanatoryCopySection from '../../../../components/ExplanatoryCopySection'

import { HeroData, FormData } from './data'

export default function InsuranceScreen({
  pageScreen,
  setPageScreen,
  insurance,
  setInsurance,
  stepperData
}: FormScreenProps) {
  return (
    <div>
      <HeroSection {...HeroData} />
      <PhoneCTADesktop />
      <MobileFormStepper pageScreen={pageScreen} stepperData={stepperData} />
      <DesktopFormStepper pageScreen={pageScreen} stepperData={stepperData} />
      <InsuranceForm
        insurance={insurance}
        setInsurance={setInsurance}
        setPageScreen={setPageScreen}
        {...FormData}
      />
      <ExplanatoryCopySection />
    </div>
  )
}
