import React from 'react'

const PastStep = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
    <circle cx="9" cy="9" r="8.5" fill="#177C8F" stroke="#177C8F" />
    <path
      fill="#fff"
      d="M14.204 5.206a.893.893 0 0 0-1.282.002l-5.784 5.98L4.867 8.82a.877.877 0 1 0-1.261 1.22l2.818 2.893a1 1 0 0 0 1.43.002l6.348-6.482c.339-.346.34-.9.002-1.247Z"
    />
  </svg>
)

export default PastStep
