import React from 'react'
import cn from 'classnames'

import { useForm, useController, UseControllerProps } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import Body from '../../Type/Body'
import Button from '../../Button'
import TextField from '../Fields/TextField'
import FieldTooltip from '../../HTMLToolTip'
import DataProtectionStatement from '../../DataProtectionStatement'
import { info } from '../../../utils/logs'

import {
  InsuranceProps,
  PageScreen
} from '../../../routes/pages/FormScreens/FormScreenTypes'

import { InsuranceTooltipCopies } from '../../../routes/pages/FormScreens/InsuranceScreen/data'
import { FieldWrapperStyles } from '../styles'

import '../styles/styles.css'
import './styles.css'

const InsuranceFormWrapper = `
  m-auto
  px-6
  pt-8
  lg:pt-10
`
export const TwoLineFieldStyle = `
  grid
  grid-cols-1
  gap-x-0
  lg:grid-cols-2
  lg:gap-x-5
`

export const ToolTipNextToFieldStyle = `
  flex
  grid-cols-2
  gap-x-0
  place-items-start
  items-center
`

const BottomFormContentWrapper = `
  text-center
  pt-6
  pb-6
  lg:pt-9
`
const DataProtectionStatementWrapper = `
  pb-3
`
const SubmitButtonWrapper = `
  pt-3
`
const SubmitButtonStyles = `
  w-full
  lg:w-auto
`

const SkipForNowWrapper = `
  pt-9
`

const schema = yup.object().shape({
  providerName: yup.string(),
  idNumber: yup.string(),
  groupNumber: yup.string(),
  rxPCN: yup.string(),
  rxBIN: yup.string()
})

interface InputFieldProps extends UseControllerProps<InsuranceProps> {
  inputLabel: string
  type?: string
  setSkipMessageActive?: Function
  inputHelperText?: string
  extraClassNames?: string
  dataID: string
}

function InputField({
  name,
  control,
  defaultValue,
  inputLabel,
  setSkipMessageActive,
  inputHelperText = '',
  extraClassNames = '',
  dataID
}: InputFieldProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  })

  const helperText =
    typeof error === 'object' && error !== null
      ? 'message' in error
        ? `${error.message} - ${inputHelperText}`
        : inputHelperText
      : inputHelperText

  return (
    <div className={cn(FieldWrapperStyles)}>
      <TextField
        className={cn('w-full', extraClassNames)}
        label={inputLabel}
        onChange={(newValue) => {
          onChange(newValue)
          if (setSkipMessageActive) {
            setSkipMessageActive(null)
          }
        }}
        onBlur={onBlur}
        value={value}
        name={name}
        inputRef={ref}
        error={invalid}
        helperText={helperText}
        dataID={dataID}
      />
    </div>
  )
}

interface InsuranceFormProps {
  insurance: InsuranceProps
  setInsurance: Function
  setPageScreen: Function
  submitButtonCopy?: string
  skipForNowCopy?: string
  midFormCopy?: string
}

const InsuranceForm = ({
  insurance,
  setInsurance,
  setPageScreen,
  submitButtonCopy = 'Submit & Continue',
  skipForNowCopy = 'Skip for Now',
  midFormCopy
}: InsuranceFormProps) => {
  const { control, handleSubmit } = useForm<InsuranceProps>({
    resolver: yupResolver(schema),
    defaultValues: insurance
  })

  const [skipMessageActive, setSkipMessageActive] = React.useState<
    string | null
  >(null)

  const onSubmit = (data: InsuranceProps) => {
    if (!data.providerName?.length) {
      setSkipMessageActive(
        'Enter your insurance information or select “Skip for Now” to continue'
      )
    } else {
      setInsurance(data)
      setPageScreen(PageScreen.SUMMARY)
      window.scrollTo(0, 0)
      info('Insurance submitted, entering summary screen')
    }
  }

  const skipForNowSubmit = (data: InsuranceProps) => {
    setInsurance(data)
    setPageScreen(PageScreen.SUMMARY)
    window.scrollTo(0, 0)
    info('Insurance skipped, entering summary screen')
  }

  return (
    <div className={cn(InsuranceFormWrapper, 'FormMaxWidth')}>
      <form aria-label="insurance">
        <InputField
          name="providerName"
          control={control}
          inputLabel="Insurance Company"
          setSkipMessageActive={setSkipMessageActive}
          dataID="input-insurance-providerName"
        />

        <div className={TwoLineFieldStyle}>
          <div className={ToolTipNextToFieldStyle}>
            <InputField
              name="idNumber"
              control={control}
              inputLabel="Member ID"
              inputHelperText="Usually 12-14 digits"
              extraClassNames="ShortFieldLength"
              dataID="input-insurance-idNumber"
            />
            <FieldTooltip
              copy={InsuranceTooltipCopies.memberId}
              title="idNumberTooltip"
              classnames="pb-6"
            />
          </div>

          <div className={cn(ToolTipNextToFieldStyle, 'pb-6')}>
            <InputField
              name="groupNumber"
              control={control}
              inputLabel="Group Number"
              extraClassNames="ShortFieldLength"
              dataID="input-insurance-groupNumber"
            />
            <FieldTooltip
              copy={InsuranceTooltipCopies.groupNumber}
              title="groupNumberTooltip"
            />
          </div>
        </div>

        {midFormCopy ? (
          <Body variant="body-small" className="font-semibold">
            {midFormCopy}
          </Body>
        ) : null}

        <div className={TwoLineFieldStyle}>
          <div className={cn(ToolTipNextToFieldStyle)}>
            <InputField
              name="rxPCN"
              control={control}
              inputLabel="Rx PCN"
              extraClassNames="ShortFieldLength"
              dataID="input-insurance-rxPCN"
            />
            <FieldTooltip
              copy={InsuranceTooltipCopies.pcn}
              title="rxPCNTooltip"
            />
          </div>

          <div className={cn(ToolTipNextToFieldStyle)}>
            <InputField
              name="rxBIN"
              control={control}
              inputLabel="Rx Bin"
              extraClassNames="ShortFieldLength"
              dataID="input-insurance-rxBIN"
            />
            <FieldTooltip
              copy={InsuranceTooltipCopies.bin}
              title="rxBINTooltip"
            />
          </div>
        </div>

        <div className={BottomFormContentWrapper}>
          <div className={DataProtectionStatementWrapper}>
            <DataProtectionStatement />
          </div>
          {skipMessageActive ? (
            <Body variant="label-small" className="text-error px-2">
              {skipMessageActive}
            </Body>
          ) : null}
          <div className={SubmitButtonWrapper}>
            <Button
              title="Submit"
              type="submit"
              handleClick={handleSubmit(onSubmit)}
              className={SubmitButtonStyles}
              dataID="button-submit-insurance"
            >
              {submitButtonCopy}
            </Button>
          </div>
          <div className={SkipForNowWrapper}>
            <Button
              title="Skip"
              type="submit"
              handleClick={handleSubmit(skipForNowSubmit)}
              variant="white"
              dataID="button-skipSubmit-insurance"
            >
              {skipForNowCopy}
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default InsuranceForm
