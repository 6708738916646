import React from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useForm, useController, UseControllerProps } from 'react-hook-form'
import { differenceInYears } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import TextField from '../Fields/TextField'
import DatePicker from '../Fields/DatePicker'
import NumberMaskedInput from '../Fields/NumberMaskedField'

import Button from '../../Button'
import Body from '../../Type/Body'

import { FieldWrapperStyles } from '../styles'
import DataProtectionStatement from '../../DataProtectionStatement'

import {
  ContactProps,
  InsuranceProps,
  PromoProps
} from '../../../routes/pages/FormScreens/FormScreenTypes'
import formatDataAndPostRequest from './request'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Heading from '../../Type/Heading'
import { styled } from '@mui/material/styles'

import { debug } from '../../../utils/logs'

import { TwoLineFieldStyle, ToolTipNextToFieldStyle } from '../Insurance'
import FieldTooltip from '../../HTMLToolTip'
import { InsuranceTooltipCopies } from '../../../routes/pages/FormScreens/InsuranceScreen/data'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

type SummaryProps = ContactProps & InsuranceProps & PromoProps

const schema = yup.object().shape({
  firstName: yup.string().required('Enter your first name'),
  lastName: yup.string().required('Enter your last name'),
  phoneNumber: yup
    .string()
    .transform((value) => {
      return value.replace(/[^0-9]/g, '')
    })
    .min(10, 'Enter your phone number')
    .required('Enter your phone number'),
  dateOfBirth: yup
    .date()
    .nullable()
    .test(
      'dateOfBirth',
      'You must be older than 13 to enroll online at divvyDOSE.',
      (value) => {
        if (value === null || value === undefined) {
          return false
        }
        return differenceInYears(Date.now(), value) >= 13
      }
    )
    .test('dateOfBirth', 'Enter a valid birthday', (value) => {
      if (value === null || value === undefined) {
        return false
      }
      return differenceInYears(Date.now(), value) <= 120
    })
    .required('Enter your birthday'),
  email: yup
    .string()
    .matches(
      /^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      'Enter a valid email'
    ),
  providerName: yup.string(),
  idNumber: yup.string(),
  groupNumber: yup.string(),
  rxPCN: yup.string(),
  rxBIN: yup.string()
})

interface InputFieldProps extends UseControllerProps<SummaryProps> {
  inputLabel: string
  type?: string
  inputHelperText?: string
  dataID: string
}

function InputField({
  name,
  control,
  defaultValue,
  inputLabel,
  inputHelperText = '',
  dataID
}: InputFieldProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  })

  // helperText will display the helper text under the input field
  // there are 4 possible outcomes:
  // 1) helperText returns both an error message and inputHelperText
  //    - there IS an error
  //    - inputHelperText has a non blank value
  // 2) helperText returns an error message
  //    - there IS an error
  //    - inputHelperText is blank (set to '')
  // 3) helperText returns the inputHelperText copy
  //    - there is no error
  //    - inputHelperText has a non blank value
  // 4) helperText returns an empty, blank string (appearing empty)
  //    - there is no error
  //    - inputHelperText is blank (set to '')
  const helperText =
    typeof error === 'object' && error !== null
      ? 'message' in error
        ? inputHelperText && inputHelperText !== ''
          ? `${error.message} - ${inputHelperText}`
          : error.message
        : inputHelperText
      : inputHelperText

  return (
    <div className={FieldWrapperStyles}>
      <TextField
        className="w-full max-w-xs"
        label={inputLabel}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        inputRef={ref}
        error={invalid}
        helperText={helperText}
        dataID={dataID}
      />
    </div>
  )
}

function DatePickerControl({
  name,
  control,
  defaultValue,
  inputLabel,
  dataID
}: InputFieldProps) {
  const {
    field: { onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  })
  return (
    <div className={FieldWrapperStyles}>
      <DatePicker
        name={name}
        label={inputLabel}
        onChange={onChange}
        invalid={invalid}
        error={error}
        defaultValue={defaultValue}
        dataID={dataID}
      />
    </div>
  )
}

interface NumberMaskedInputControlProps extends InputFieldProps {
  mask: string
  placeholder: string
}

function NumberMaskedInputControl({
  name,
  control,
  defaultValue,
  inputLabel,
  mask,
  placeholder,
  dataID
}: NumberMaskedInputControlProps) {
  const {
    field: { onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  })

  return (
    <div className={FieldWrapperStyles}>
      <NumberMaskedInput
        mask={mask}
        name={name}
        label={inputLabel}
        onChange={onChange}
        invalid={invalid}
        error={error}
        placeholder={placeholder}
        defaultValue={defaultValue}
        dataID={dataID}
      />
    </div>
  )
}

interface SummaryFormProps {
  contact: ContactProps
  setContact: Function
  insurance: InsuranceProps
  setInsurance: Function
  promoCode: string
  setPromoCode: Function
  midFormCopy?: string
  submitButtonCopy?: string
}

const hideMobileShowDesktop = `
  hidden
  lg:block
  lg:inline-block
`
const showMobileHideDesktop = `
  block
  inline-block
  lg:hidden
`

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid #177C8F`,
  borderRadius: `4px`,
  margin: `20px 24px 10px 24px`,
  '&:before': {
    display: 'none'
  }
}))

const SummaryForm = ({
  contact,
  setContact,
  insurance,
  setInsurance,
  promoCode,
  submitButtonCopy = 'Confirm & Submit',
  midFormCopy = 'Rx PCN and Rx BIN numbers may be found on a separate pharmacy benefit card.'
}: SummaryFormProps) => {
  const { control, handleSubmit } = useForm<SummaryProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...contact,
      ...insurance,
      promoCode
    }
  })

  const navigate = useNavigate()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async (data: SummaryProps) => {
    if (!executeRecaptcha) {
      debug('Execute recaptcha not yet available')
    } else {
      const token = await executeRecaptcha('submit')
      debug('Got a Token: ' + token)
      // TODO: At this point we have access to the reCAPTCHA token, we need to add this to the data payload
      //  so it is sent on to the backend for verification
    }

    setContact({
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      dateOfBirth: data.dateOfBirth,
      email: data.email
    })
    setInsurance({
      providerName: data.providerName,
      idNumber: data.idNumber,
      groupNumber: data.groupNumber,
      rxPCN: data.rxPCN,
      rxBIN: data.rxBIN
    })
    debug('Form submission', { payload: data })

    try {
      await formatDataAndPostRequest(data)
      navigate(`/success`)
    } catch (e) {
      navigate(`/error`)
    }
  }

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <div className="grid md:grid-cols-3">
      <form className="text-left pt-8 md:col-start-2">
        {/* Contact Panel*/}
        <>
          <Accordion
            expanded={expanded === 'contactPanel'}
            onChange={handleChange('contactPanel')}
            className={'contactPanel'}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Heading className={showMobileHideDesktop} as="h4" variant="h4">
                  Contact Info
                </Heading>

                <Heading className={hideMobileShowDesktop} as="h4" variant="h4">
                  Contact Information
                </Heading>

                {expanded === 'panel1' ? null : (
                  <Body className="block" variant="body-small">
                    {contact.firstName + ' ' + contact.lastName}
                  </Body>
                )}
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <InputField
                name="firstName"
                control={control}
                inputLabel="First Name"
                dataID="input-summary-firstName"
              />

              <InputField
                name="lastName"
                control={control}
                inputLabel="Last Name"
                dataID="input-summary-lastName"
              />

              <NumberMaskedInputControl
                control={control}
                name="phoneNumber"
                inputLabel="Phone Number"
                mask="(#00) 000-0000"
                placeholder="(XXX) XXX-XXXX"
                defaultValue={contact.phoneNumber}
                dataID="input-summary-phoneNumber"
              />

              <DatePickerControl
                name="dateOfBirth"
                control={control}
                inputLabel="Date of Birth"
                defaultValue={contact.dateOfBirth}
                dataID="input-summary-dateOfBirth"
              />

              <InputField
                name="email"
                control={control}
                inputLabel="Email"
                dataID="input-summary-email"
              />
            </AccordionDetails>
          </Accordion>
        </>

        {/* Insurance Panel*/}
        <>
          <Accordion
            expanded={expanded === 'insurancePanel'}
            onChange={handleChange('insurancePanel')}
            className={'insurancePanel'}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Heading className={hideMobileShowDesktop} as="h4" variant="h4">
                  Insurance Information
                </Heading>
                <Heading className={showMobileHideDesktop} as="h4" variant="h4">
                  Insurance Info
                </Heading>

                <Body className="inline-block italic text-lg lg:text-xl">
                  - Optional
                </Body>

                {expanded === 'panel2' ? null : (
                  <Body className="block" variant="body-small">
                    {insurance.providerName
                      ? insurance.providerName
                      : 'Not Entered'}
                  </Body>
                )}
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <InputField
                name="providerName"
                control={control}
                inputLabel="Insurance Company"
                dataID="input-summary-providerName"
              />

              <div className={TwoLineFieldStyle}>
                <div className={ToolTipNextToFieldStyle}>
                  <InputField
                    name="idNumber"
                    control={control}
                    inputLabel="Member ID"
                    inputHelperText="Usually 12-14 digits"
                    dataID="input-summary-idNumber"
                  />
                  <FieldTooltip
                    copy={InsuranceTooltipCopies.memberId}
                    title="idNumberTooltip"
                    classnames="pb-6"
                  />
                </div>

                <div className={cn(ToolTipNextToFieldStyle, 'pb-6')}>
                  <InputField
                    name="groupNumber"
                    control={control}
                    inputLabel="Group Number"
                    dataID="input-summary-groupNumber"
                  />
                  <FieldTooltip
                    copy={InsuranceTooltipCopies.groupNumber}
                    title="groupNumberTooltip"
                  />
                </div>
              </div>

              <Body>{midFormCopy}</Body>

              <div className={TwoLineFieldStyle}>
                <div className={cn(ToolTipNextToFieldStyle)}>
                  <InputField
                    name="rxPCN"
                    control={control}
                    inputLabel="Rx PCN"
                    dataID="input-summary-rxPCN"
                  />
                  <FieldTooltip
                    copy={InsuranceTooltipCopies.pcn}
                    title="rxPCNTooltip"
                  />
                </div>

                <div className={cn(ToolTipNextToFieldStyle)}>
                  <InputField
                    name="rxBIN"
                    control={control}
                    inputLabel="Rx Bin"
                    dataID="input-summary-rxBIN"
                  />
                  <FieldTooltip
                    copy={InsuranceTooltipCopies.bin}
                    title="rxBINTooltip"
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </>

        <div className="text-center mt-10 mb-15 lg:mt-14 lg:mb-18">
          <DataProtectionStatement />
          <Button
            title="Submit"
            type="submit"
            handleClick={handleSubmit(onSubmit)}
            className="mt-6"
            dataID="button-submit-summary"
          >
            {submitButtonCopy}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default SummaryForm
