import React from 'react'

import { styled } from '@mui/material/styles'
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel'
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'

export const StyledMobileConnector = styled(StepConnector)({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 8,
    left: 'calc(-50% + 9px)',
    right: 'calc(50% + 9px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#177C8F'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#177C8F'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#B1B2B3',
    borderTopWidth: 5,
    borderRadius: 1
  }
})

export const StyledDesktopConnector = styled(StepConnector)({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: 8
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#177C8F'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#177C8F'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#B1B2B3',
    borderLeftWidth: 5,
    borderRadius: 0,
    height: '48px'
  }
})

export const StyledDesktopStepLabel = styled(StepLabel)({
  [`&.${stepLabelClasses.root}`]: {
    padding: 0,
    margin: '-1px 0',
    fontFamily: 'Montserrat',
    color: '#717171',
    fontWeight: 500
  },
  [`.${stepLabelClasses.label}`]: {
    fontFamily: 'Montserrat',
    paddingLeft: '4px'
  },
  [`.${stepLabelClasses.active}`]: {
    color: '#177C8F',
    fontWeight: 700
  },
  [`.${stepLabelClasses.completed}`]: {
    color: '#717171',
    fontWeight: 500
  }
})

export const StyledMobileStepLabel = styled(StepLabel)({
  [`&.${stepLabelClasses.root}`]: {
    fontFamily: 'Montserrat',
    color: '#717171',
    fontWeight: 500
  },
  [`.${stepLabelClasses.label}`]: {
    fontFamily: 'Montserrat',
    margin: '8px 0 0'
  },
  [`.${stepLabelClasses.label}.${stepLabelClasses.active}`]: {
    color: '#177C8F',
    fontWeight: 700
  },
  [`.${stepLabelClasses.label}.${stepLabelClasses.completed}`]: {
    color: '#717171',
    fontWeight: 500
  }
})

export const hideMobileShowDesktop = `
  hidden
  lg:block
`
export const showMobileHideDesktop = `
  block
  lg:hidden
`
