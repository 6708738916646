export interface IconRowCopyProps {
  headline: string
  copy?: string
  mobileHeadline: string
  mobileCopy?: string
}

export enum IconRowIconProps {
  PLUS = 'plus',
  BOX = 'box',
  MONEY = 'money'
}
export interface IconRowCardProps extends IconRowCopyProps {
  icon: IconRowIconProps
}

export interface IconRowSectionProps extends IconRowCopyProps {
  video?: string
  cards: IconRowCardProps[]
}
