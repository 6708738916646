import { PageScreen } from './FormScreenTypes'

export const stepperData = [
  {
    formScreen: PageScreen.CONTACT,
    copy: 'Contact Info',
    mobileCopy: 'Contact'
  },
  {
    formScreen: PageScreen.INSURANCE,
    copy: 'Insurance',
    mobileCopy: 'Insurance'
  },
  {
    formScreen: PageScreen.SUMMARY,
    copy: 'Review & Complete',
    mobileCopy: 'Complete'
  }
]
