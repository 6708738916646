import React from 'react'

import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { formHelperTextClasses } from '@mui/material/FormHelperText'
import {
  default as MaterialTextField,
  TextFieldProps
} from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

import ErrorIcon from '@mui/icons-material/Error'
import InputAdornment from '@mui/material/InputAdornment'

const StyledTextField = styled(MaterialTextField)({
  [`.${outlinedInputClasses.root}`]: {
    fontSize: '18px'
  },
  [`.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: '#717171'
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: '#404040'
    },
  [`.${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: '#177c8f'
    },
  [`.${outlinedInputClasses.input}`]: {
    color: '#717171',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 400
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    color: '#404040',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    fontWeight: 400
  },
  [`.${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
    {
      color: '#404040',
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: 400
    },
  [`.${inputLabelClasses.outlined}`]: {
    color: '#717171',
    fontFamily: 'Montserrat',
    fontWeight: 400
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: '#404040',
    fontFamily: 'Montserrat',
    fontWeight: 400
  },
  [`.${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: '#177c8f',
    fontFamily: 'Montserrat',
    fontWeight: 400
  },

  [`.${outlinedInputClasses.root}.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: '#ca364c'
    },
  [`&:hover .${outlinedInputClasses.root}.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: '#ca364c'
    },
  [`.${inputLabelClasses.outlined}.${outlinedInputClasses.error}`]: {
    color: '#ca364c'
  },

  // HelperText
  [`.${formHelperTextClasses.root}`]: {
    color: '#404040',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 400
  },

  // Error Helper Text
  [`.${formHelperTextClasses.root}.${formHelperTextClasses.error}`]: {
    color: '#ca364c',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 400
  }
})

// InputProps={{
//   endAdornment: props.error ? (
//     <InputAdornment position="end">
//       <ErrorIcon color="error" />
//     </InputAdornment>
//   ) : null
// }}

type SpecialTextFieldProps = TextFieldProps & { dataID: string }
const TextField = (props: SpecialTextFieldProps) => {
  return <StyledTextField {...props} id={props.dataID} />
}
export default TextField
