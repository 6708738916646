import React from 'react'

const FutureStep = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
    <circle
      cx="9"
      cy="9"
      r="8.5"
      fill="#fff"
      stroke="#B1B2B3"
      transform="rotate(-90 9 9)"
    />
  </svg>
)

export default FutureStep
