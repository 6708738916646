import React from 'react'

export default function PhoneIcon() {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#196ECF" />
      <path
        d="M24.6427 8.66675C21.0668 8.66675 18.7543 10.6676 18.7543 13.768C18.7543 14.9072 19.0754 15.9275 19.6911 16.755L18.4224 19.436H19.948L21.9287 18.4044C22.737 18.7161 23.647 18.8692 24.6427 18.8692C28.2186 18.8692 30.5311 16.8684 30.5311 13.768C30.5311 10.6676 28.2186 8.66675 24.6427 8.66675ZM22.5014 14.6182C22.0571 14.6182 21.6985 14.2384 21.6985 13.768C21.6985 13.2975 22.0571 12.9178 22.5014 12.9178C22.9458 12.9178 23.3044 13.2975 23.3044 13.768C23.3044 14.2384 22.9458 14.6182 22.5014 14.6182ZM24.6427 14.6182C24.1984 14.6182 23.8397 14.2384 23.8397 13.768C23.8397 13.2975 24.1984 12.9178 24.6427 12.9178C25.087 12.9178 25.4457 13.2975 25.4457 13.768C25.4457 14.2384 25.087 14.6182 24.6427 14.6182ZM26.7839 14.6182C26.3396 14.6182 25.981 14.2384 25.981 13.768C25.981 13.2975 26.3396 12.9178 26.7839 12.9178C27.2283 12.9178 27.5869 13.2975 27.5869 13.768C27.5869 14.2384 27.2283 14.6182 26.7839 14.6182Z"
        fill="white"
      />
      <path
        d="M24.3917 26.1392L19.4658 24.7186L18.1695 26.0911C16.6074 25.0892 14.5334 22.8931 13.5871 21.2392L14.8834 19.8666L13.5417 14.6509C13.3861 14.047 12.8741 13.6353 12.2908 13.6353H8.8491L8.03244 14.4931L8.01299 14.7402C7.99355 14.9735 7.5917 20.4705 13.2435 26.448C18.0982 31.5951 22.6158 32 23.925 32C24.1389 32 24.2685 31.9862 24.301 31.9862L24.5343 31.9657L25.351 31.1009V27.4568C25.3445 26.846 24.9556 26.297 24.3917 26.1392Z"
        fill="white"
      />
    </svg>
  )
}
