import React from 'react'
import { FormScreenProps } from '../FormScreenTypes'

import HeroSection from '../../../../components/Hero'
import PhoneCTADesktop from '../../../../components/PhoneCTADesktop'
import MobileFormStepper from '../../../../components/FormStepper/MobileFormStepper'
import DesktopFormStepper from '../../../../components/FormStepper/DesktopFormStepper'
import SummaryForm from '../../../../components/Form/Summary'

import { HeroData } from './data'

export default function SummaryScreen({
  pageScreen,
  contact,
  setContact,
  insurance,
  setInsurance,
  promoCode,
  setPromoCode,
  stepperData
}: FormScreenProps) {
  return (
    <div>
      <HeroSection {...HeroData} />
      <PhoneCTADesktop />
      <MobileFormStepper pageScreen={pageScreen} stepperData={stepperData} />
      <DesktopFormStepper pageScreen={pageScreen} stepperData={stepperData} />
      <SummaryForm
        contact={contact}
        setContact={setContact}
        insurance={insurance}
        setInsurance={setInsurance}
        promoCode={promoCode}
        setPromoCode={setPromoCode}
      />
    </div>
  )
}
