/* eslint-disable no-console */
import React from 'react'
import cn from 'classnames'
import { useForm, useController, UseControllerProps } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import validationRules from './validationRules'
import TextField from '../Fields/TextField'
import DatePicker from '../Fields/DatePicker'
import NumberMaskedInput from '../Fields/NumberMaskedField'
import Button from '../../Button'
import DataProtectionStatement from '../../DataProtectionStatement'
import TermsAndConditionsSection from '../../TermsAndConditionsSection'
import { info } from '../../../utils/logs'

import {
  ContactProps,
  PageScreen
} from '../../../routes/pages/FormScreens/FormScreenTypes'

import { FieldWrapperStyles } from '../styles'
import './styles.css'
import '../styles/styles.css'

const ContactFormOuterWrapper = `
  m-auto
  px-6
  pt-8
  pb-8
  lg:pt-10
  lg:pb-20
`
const ContactFormWrapper = `
  m-auto
`

const SubmitButtonWrapper = `
  text-center

  pt-6
  pb-6
  lg:pt-9
`
const SubmitButtonStyles = `
  w-full
  lg:w-auto
  mt-6
`
interface InputFieldProps extends UseControllerProps<ContactProps> {
  inputLabel: string
  dataID: string
  type?: string
}

function InputField({
  name,
  control,
  defaultValue,
  inputLabel,
  dataID
}: InputFieldProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  })

  const helperText =
    typeof error === 'object' && error !== null
      ? 'message' in error
        ? error.message
        : ''
      : ''

  return (
    <div className={FieldWrapperStyles}>
      <TextField
        className="w-full"
        label={inputLabel}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        inputRef={ref}
        error={invalid}
        helperText={helperText}
        dataID={dataID}
      />
    </div>
  )
}

function DatePickerControl({
  name,
  control,
  defaultValue,
  inputLabel,
  dataID
}: InputFieldProps) {
  const {
    field: { onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  })

  return (
    <div className={FieldWrapperStyles}>
      <DatePicker
        name={name}
        label={inputLabel}
        onChange={onChange}
        invalid={invalid}
        error={error}
        dataID={dataID}
      />
    </div>
  )
}

interface NumberMaskedInputControlProps extends InputFieldProps {
  mask: string
  placeholder: string
}

function NumberMaskedInputControl({
  name,
  control,
  defaultValue,
  inputLabel,
  mask,
  placeholder,
  dataID
}: NumberMaskedInputControlProps) {
  const {
    field: { onChange },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    defaultValue
  })

  return (
    <div className={FieldWrapperStyles}>
      <NumberMaskedInput
        mask={mask}
        name={name}
        label={inputLabel}
        onChange={onChange}
        invalid={invalid}
        error={error}
        placeholder={placeholder}
        dataID={dataID}
      />
    </div>
  )
}

interface ContactFormProps {
  submitButtonCopy?: string
  contact: ContactProps
  setContact: Function
  setPageScreen: Function
}

const ContactForm = ({
  submitButtonCopy = 'Submit & Continue',
  contact,
  setContact,
  setPageScreen
}: ContactFormProps) => {
  const { control, handleSubmit } = useForm<ContactProps>({
    resolver: yupResolver(validationRules),
    defaultValues: contact
  })

  const onSubmit = (data: ContactProps) => {
    setContact(data)
    setPageScreen(PageScreen.INSURANCE)
    info('Entering insurance screen')
    window.scrollTo(0, 0)
  }

  return (
    <div
      className={cn('ContactFormOuterWrapperMaxWidth', ContactFormOuterWrapper)}
    >
      <div className={cn('FormMaxWidth', ContactFormWrapper)}>
        <form className="text-left" title="Contact Info">
          <InputField
            name="firstName"
            control={control}
            inputLabel="First Name"
            dataID="input-contact-firstName"
          />

          <InputField
            name="lastName"
            control={control}
            inputLabel="Last Name"
            dataID="input-contact-lastName"
          />

          <NumberMaskedInputControl
            control={control}
            name="phoneNumber"
            inputLabel="Phone Number"
            mask="(#00) 000-0000"
            placeholder="(XXX) XXX-XXXX"
            dataID="input-contact-phoneNumber"
          />

          <DatePickerControl
            name="dateOfBirth"
            control={control}
            inputLabel="Date of Birth"
            dataID="input-contact-dateOfBirth"
          />

          <InputField
            name="email"
            control={control}
            inputLabel="Email"
            dataID="input-contact-email"
          />

          <div className={SubmitButtonWrapper}>
            <DataProtectionStatement />
            <Button
              title="Submit"
              type="submit"
              handleClick={handleSubmit(onSubmit)}
              className={SubmitButtonStyles}
              dataID="button-SubmitContact"
            >
              {submitButtonCopy}
            </Button>
          </div>
        </form>
      </div>
      <TermsAndConditionsSection />
    </div>
  )
}

export default ContactForm
