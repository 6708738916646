import React from 'react'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import TextField from './TextField'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import { FieldError } from 'react-hook-form'

import '../styles/styles.css'

interface DatePickerProps {
  name: string
  label: string
  onChange: Function
  invalid: boolean
  error: FieldError | null | undefined
  defaultValue?: Date | null | string | {}
  dataID: string
}

function DatePicker({
  name,
  label,
  onChange,
  invalid,
  error,
  defaultValue = null,
  dataID
}: DatePickerProps) {
  const [date, setDate] = React.useState<Date | null | string | {}>(
    defaultValue
  )

  const helperText =
    typeof error === 'object' && error !== null
      ? 'message' in error
        ? error.message
        : ''
      : ''

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        value={date}
        onChange={(newValue) => {
          setDate(newValue)
          onChange(newValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            helperText={helperText}
            error={invalid}
            className="ShortFieldLength"
            dataID={dataID}
          />
        )}
      />
    </LocalizationProvider>
  )
}
export default DatePicker
