import React from 'react'

import Routes from './routes'
import Nav from './components/Nav'

import './styles/hero.css'

function App() {
  return (
    <div>
      <Nav />
      <Routes />
    </div>
  )
}

export default App
