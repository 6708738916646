import React from 'react'
import Body from './Type/Body'
import A from './Type/A'
import cn from 'classnames'

const links = [
  {
    text: 'Terms of Use,',
    url: 'https://divvydose.com/terms-of-use/'
  },
  {
    text: 'Privacy Policy',
    url: 'https://divvydose.com/privacy-policy/'
  }
]

const TermsAndConditionsVariant = {
  default: `
    text-center
    lg:text-base
  `
}
type TermsAndConditionsVariantTypes = keyof typeof TermsAndConditionsVariant

export interface TermsAndConditionsProps {
  className?: string
  variant?: TermsAndConditionsVariantTypes
}

export default function TermsAndConditionsSection({
  className,
  variant = 'default'
}: TermsAndConditionsProps) {
  return (
    <section
      className={cn(TermsAndConditionsVariant[variant], className)}
      aria-label="Terms and Conditions Links"
    >
      <Body variant="label-small">
        By continuing, you agree to divvyDOSE’s
        <br />
        {links.map((item) => (
          <A
            key={item.url}
            className="pr-1 underline"
            variant="label-small"
            url={item.url}
          >
            {item.text}
          </A>
        ))}
      </Body>
    </section>
  )
}
