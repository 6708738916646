import React, { ReactNode } from 'react'
import cn from 'classnames'

export const AVariants = {
  default: `text-base font-bold leading-5 text-primary`,
  'default-black': `text-base font-bold leading-5`,
  inline: `text-base font-bold leading-5 text-primary underline`,
  'label-small': `text-sm lg:text-base font-bold leading-5 text-primary`,
  button: `px-10 rounded-full border
  text-mono-white bg-primary border-primary
  py-3 text-lg font-bold text-center
  `
}

type AVariantType = keyof typeof AVariants
export interface AProps {
  children: ReactNode
  url: string
  target?: '_blank' | '_self'
  variant?: AVariantType
  className?: string
}

const A = ({
  children,
  url,
  target = '_self',
  variant = 'default',
  className
}: AProps) => (
  <a
    className={cn(AVariants[variant], className)}
    rel="noopener noreferrer"
    href={url}
    target={target}
  >
    {children}
  </a>
)
export default A
