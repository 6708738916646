import * as Sentry from '@sentry/browser'
import { datadogLogs } from '@datadog/browser-logs'

import {
  isDebug,
  isDevelopment,
  isProduction,
  isPreprod,
  isTest
} from '../environment'

/**
 * The logging instance.
 */
const logger = console

async function collectDatadogLog(
  message: string,
  context: object | undefined,
  {
    type
  }: {
    type: 'debug' | 'error' | 'info' | 'warn'
  }
) {
  if (isTest()) {
    return
  }

  datadogLogs.logger[type](message, context)
}

/**
 * Outputs an error message and captures the message as a Sentry exception.
 */
export function critical(message: string, context?: object) {
  if (isTest()) {
    return
  }

  error(message, context)

  if (isProduction() || isPreprod()) {
    Sentry.captureException(new Error(message))
  }
}

/**
 * Outputs a debug message.
 */
export function debug(message: string, context?: object) {
  if (isDebug() && !isTest()) {
    collectDatadogLog(message, context, { type: 'debug' })
    if (context) {
      logger.debug(message, context)
    } else {
      logger.debug(message)
    }
  }
}

/**
 * Outputs an error message.
 */
export function error(message: string, context?: object) {
  if (isTest()) {
    return
  }

  collectDatadogLog(message, context, { type: 'error' })

  if (context) {
    logger.error(message, context)
  } else {
    logger.error(message)
  }
}

/**
 * Outputs a grouped info message.
 */
export function group(
  message: string,
  context: object | undefined,
  {
    isCollapsed = false
  }: {
    isCollapsed?: boolean
  }
) {
  if (isDevelopment() && !isTest()) {
    if (isCollapsed) {
      logger.groupCollapsed(message)
    } else {
      logger.group(message)
    }

    if (context) {
      logger.info(message, context)
    } else {
      logger.info(message)
    }
    logger.groupEnd()
  }
}

/**
 * Outputs an info message.
 */
export function info(message: string, context?: object) {
  collectDatadogLog(message, context, { type: 'info' })

  if (!isTest() && !isProduction()) {
    if (context) {
      logger.info(message, context)
    } else {
      logger.info(message)
    }
  }
}

/**
 * Outputs an warning message.
 */
export function warn(message: string, context?: object) {
  collectDatadogLog(message, context, { type: 'warn' })

  if (!isTest() && !isProduction()) {
    if (context) {
      logger.warn(message, context)
    } else {
      logger.warn(message)
    }
  }
}
