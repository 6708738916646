import React from 'react'

import HeroSection from '../../../../components/Hero'
import PhoneCTADesktop from '../../../../components/PhoneCTADesktop'
import FormHero from '../../../../components/FormHero'
import MobileFormStepper from '../../../../components/FormStepper/MobileFormStepper'
import DesktopFormStepper from '../../../../components/FormStepper/DesktopFormStepper'
import ContactForm from '../../../../components/Form/Contact'
import CopyImageCardSection from '../../../../components/CopyImageCardSection'
import IconRowSection from '../../../../components/IconRowSection'

import { FormScreenProps } from '../FormScreenTypes'

import {
  HeroData,
  FormHeroData,
  IconRowSectionData,
  CopyImageCardOne,
  CopyImageCardTwo
} from './data'

export default function ContactScreen({
  pageScreen,
  setPageScreen,
  contact,
  setContact,
  stepperData
}: FormScreenProps) {
  return (
    <div>
      <HeroSection {...HeroData} />
      <PhoneCTADesktop />
      <FormHero {...FormHeroData} />

      <MobileFormStepper pageScreen={pageScreen} stepperData={stepperData} />
      <DesktopFormStepper pageScreen={pageScreen} stepperData={stepperData} />

      <ContactForm
        contact={contact}
        setContact={setContact}
        setPageScreen={setPageScreen}
      />

      <main>
        <CopyImageCardSection {...CopyImageCardOne} />
        <IconRowSection {...IconRowSectionData} />
        <CopyImageCardSection {...CopyImageCardTwo} />
      </main>
    </div>
  )
}
