import React from 'react'
import cn from 'classnames'

import Gutter from '../Gutter'
import Body from '../Type/Body'
import A from '../Type/A'

import PhoneIcon from './PhoneIcon'
import './phoneCTADesktop.css'

const hideMobileShowDesktop = `
  hidden
  lg:block
`
export default function PhoneCTADesktop() {
  return (
    <aside
      aria-label="Call Us Action"
      className={cn(
        hideMobileShowDesktop,
        'relative max-w-full phoneCTA-wrapper'
      )}
    >
      <Gutter>
        <div className="flex justify-end">
          <div className="mr-2">
            <PhoneIcon />
          </div>
          <Body variant="body-small">
            Prefer to enroll over the <br /> phone? &nbsp;
            <A url="tel:844-693-4889" variant="default-black">
              Call: 844-693-4889
            </A>
          </Body>
        </div>
      </Gutter>
    </aside>
  )
}
