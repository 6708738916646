import React from 'react'

export default function CircledNumberTwo() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.501" r="12" fill="#196ECF" />
      <path
        d="M15.5249 15.5101V17.3581H8.11888V15.8881L11.8989 12.3181C12.3002 11.9355 12.5709 11.6088 12.7109 11.3381C12.8509 11.0581 12.9209 10.7828 12.9209 10.5121C12.9209 10.1201 12.7855 9.82146 12.5149 9.61612C12.2535 9.40146 11.8662 9.29412 11.3529 9.29412C10.9235 9.29412 10.5362 9.37812 10.1909 9.54612C9.84554 9.70479 9.55621 9.94746 9.32288 10.2741L7.67088 9.21012C8.05354 8.64079 8.58088 8.19746 9.25288 7.88012C9.92488 7.55346 10.6949 7.39012 11.5629 7.39012C12.2909 7.39012 12.9255 7.51146 13.4669 7.75412C14.0175 7.98746 14.4422 8.32346 14.7409 8.76212C15.0489 9.19146 15.2029 9.70012 15.2029 10.2881C15.2029 10.8201 15.0909 11.3195 14.8669 11.7861C14.6429 12.2528 14.2089 12.7848 13.5649 13.3821L11.3109 15.5101H15.5249Z"
        fill="white"
      />
    </svg>
  )
}
