import * as yup from 'yup'
import { differenceInYears } from 'date-fns'

const validationRules = yup.object().shape({
  firstName: yup.string().required('Enter your first name'),
  lastName: yup.string().required('Enter your last name'),
  phoneNumber: yup
    .string()
    .transform((value) => {
      return value.replace(/[^0-9]/g, '')
    })
    .min(10, 'Enter your phone number')
    .required('Enter your phone number'),
  dateOfBirth: yup
    .date()
    .nullable()
    .test(
      'dateOfBirth',
      'You must be older than 13 to enroll online at divvyDOSE. If you are a parent or guardian, please call 844-693-4889 to enroll your child by phone.',
      (value) => {
        if (value === null || value === undefined) {
          return false
        }
        return differenceInYears(Date.now(), value) >= 13
      }
    )
    .test('dateOfBirth', 'Enter a valid birthday', (value) => {
      if (value === null || value === undefined) {
        return false
      }
      return differenceInYears(Date.now(), value) <= 120
    })
    .required('Enter your birthday'),
  email: yup
    .string()
    .matches(
      /^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      'Enter a valid email'
    )
})

export default validationRules
