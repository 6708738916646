import React, { ReactNode } from 'react'

export interface GutterProps {
  children: ReactNode
}

const GutterStyles = `
  px-mobileGutter
  lg:px-desktopGutter
`

export default function Gutter({ children }: GutterProps) {
  return <div className={GutterStyles}>{children}</div>
}
