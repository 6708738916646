import React, { ReactNode } from 'react'
import cn from 'classnames'

const BodyVariants = {
  body: `text-lg font-normal leading-6 text-mono-700`,
  'body-small': `text-base font-normal leading-5 text-mono-700`,
  helper: `text-lg font-normal leading-6 text-mono-600`,
  label: `text-lg font-medium leading-6 text-mono-700`,
  'label-small': `text-sm font-normal leading-4 text-mono-600`,
  'label-small-white': `text-sm font-normal leading-4 text-mono-white`,
  'hero-content-small-label': `text-base font-medium leading-6 text-mono-700`
}
type BodyVariantType = keyof typeof BodyVariants

export interface BodyProps {
  children: ReactNode
  variant?: BodyVariantType
  className?: string
}

const Body = ({ children, variant = 'body', className }: BodyProps) => (
  <p className={cn(BodyVariants[variant], className)}>{children}</p>
)
export default Body
