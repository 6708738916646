import React from 'react'

export default function PlusIcon() {
  return (
    <svg
      className="m-auto"
      width="80"
      height="80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="26.75" stroke="#177C8F" strokeWidth="2.5" />
      <rect x="37" y="26" width="6" height="30" rx="3" fill="#196ECF" />
      <rect
        x="25"
        y="43.999"
        width="6"
        height="30"
        rx="3"
        transform="rotate(-90 25 44)"
        fill="#196ECF"
      />
    </svg>
  )
}
