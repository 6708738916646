import React from 'react'
import { useLocation } from 'react-router-dom'
import Error from '../../components/Error'
import Footer from '../../components/Footer'
import ScrollToTop from '../../components/ScrollToTop'

const errorPageData = {
  headline: 'Oops! Something went wrong',
  link: {
    text: 'Back',
    url: '/'
  }
}

export default function ErrorPage() {
  return (
    <div>
      <ScrollToTop />
      <Error {...errorPageData}></Error>
      <Footer />
    </div>
  )
}
