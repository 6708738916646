import divvyDOSEPharmacistDesktop from '../../../images/divvyDOSEPharmacist.jpg'
import divvyDOSEPharmacistMobile from '../../../images/divvyDOSEPharmacist-mobile.jpg'

import { ListItemDetailVariant } from '../../../components/CopyImageCardSection/types'

export const SuccessHeroData = {
  headline: 'You completed enrollment!',
  copy: 'Expect a call from our team with next steps, your calculated copays, and answers to any questions',
  mobileHeadline: 'Congratulations! You’re on your way to your first box',
  mobileCopy:
    'Expect a call from our team with next steps, your calculated copays, and answers to any questions',
  cards: [
    {
      headline: "We'll call you back within 2 business days",
      copy: "We'll collect additional information to confirm copays.",
      mobileHeadline: "We'll call you within 2 business days"
    },
    {
      headline: 'We calculate your exact copays',
      copy: "We won't charge you until you know your copays.",
      mobileHeadline: 'We calculate your exact copays'
    },
    {
      headline: 'You get your first box!',
      copy: 'Your box will arrive, delivered to your doorstep.',
      mobileHeadline: 'You get your first box!'
    }
  ]
}

export const SummaryCopyImageCardSectionData = {
  headline: 'Still have questions?',
  backgroundColor: '#FFFFFF',
  desktopImage: {
    imageUrl: divvyDOSEPharmacistDesktop,
    alt: 'divvyDOSE Pharmacist on the phone'
  },
  mobileImage: {
    imageUrl: divvyDOSEPharmacistMobile,
    alt: 'divvyDOSE Pharmacist on the phone'
  },
  listItems: [
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Our specialists are available 24/7. '
      },
      {
        variant: ListItemDetailVariant.BOLD,
        text: 'Call anytime: 844-693-4889'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Email: '
      },
      {
        variant: ListItemDetailVariant.LINK,
        text: 'hi@divvydose.com',
        url: 'mailto:hi@divvydose.com'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'If we have any questions we will reach out to you, and quickly to resolve it, and keep you informed.'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Visit our '
      },
      {
        variant: ListItemDetailVariant.LINK,
        text: 'FAQ page',
        url: 'https://divvydose.com/how-it-works/'
      },
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: ' for frequently asked questions'
      }
    ]
  ]
}
