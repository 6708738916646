import React from 'react'
import cn from 'classnames'

import Gutter from '../Gutter'
import Heading from '../Type/Heading'
import Body from '../Type/Body'

import SlantedTriangle from './images/SlantedTriangle'
import CircledNumberOne from './images/CircledNumberOne'
import CircledNumberTwo from './images/CircledNumberTwo'
import CircledNumberThree from './images/CircledNumberThree'
import CircledNumberFour from './images/CircledNumberFour'

export interface HeroCopyProps {
  headline: string
  copy: string
  mobileHeadline: string
  mobileCopy?: string
}

export interface HeroProps extends HeroCopyProps {
  cards?: HeroCopyProps[]
}

const hideMobileShowDesktop = `
  hidden
  lg:block
`
const showMobileHideDesktop = `
  block
  lg:hidden
`
const heroMainBlock = `
  text-center
  pt-7
  pb-5
  lg:pt-12
  lg:pb-8
`

const CircleImages = [
  CircledNumberOne,
  CircledNumberTwo,
  CircledNumberThree,
  CircledNumberFour
]

const cardGridWrapperCommon = `
  grid
  gap-x-0
  grid-cols-1
  lg:grid-rows-1
  lg:gap-x-16

  m-auto
  max-w-sm
  lg:max-w-full
`
const cardGridOneWrapper = `
  grid-rows-1
  lg:grid-cols-1
`
const cardGridTwoWrapper = `
  grid-rows-2
  lg:grid-cols-2
`
const cardGridThreeWrapper = `
  grid-rows-3
  lg:grid-cols-3
`
const cardGridFourWrapper = `
  grid-rows-4
  lg:grid-cols-4
`
const CardGridBlockWrapperStyles = [
  cardGridOneWrapper,
  cardGridTwoWrapper,
  cardGridThreeWrapper,
  cardGridFourWrapper
]

const cardGridBlockCommon = `
  flex
`
const cardGridBlockOne = `
  lg:col-start-1
`
const cardGridBlockTwo = `
  lg:col-start-2
`
const cardGridBlockThree = `
  lg:col-start-3
`
const cardGridBlockFour = `
  lg:col-start-4
`
const CardGridBlockStyles = [
  cardGridBlockOne,
  cardGridBlockTwo,
  cardGridBlockThree,
  cardGridBlockFour
]

export default function HeroSection({
  headline,
  copy,
  mobileHeadline,
  mobileCopy = '',
  cards = []
}: HeroProps) {
  return (
    <div className="relative" role="banner">
      <div className="bg-mono-mint">
        <Gutter>
          <div className={heroMainBlock}>
            <Heading as="h1" variant="h1">
              <span className={cn(showMobileHideDesktop)}>
                {mobileHeadline}
              </span>
              <span className={cn(hideMobileShowDesktop)}>{headline}</span>
            </Heading>
            <Heading as="h2" variant="h5" className="py-1">
              <span className={cn(showMobileHideDesktop)}>{mobileCopy}</span>
              <span className={cn(hideMobileShowDesktop)}>{copy}</span>
            </Heading>
          </div>

          {cards.length === 0 ? null : (
            <div
              className={cn(
                CardGridBlockWrapperStyles[cards.length - 1],
                cardGridWrapperCommon
              )}
            >
              {cards.map((card, index) => {
                const CircleImage = CircleImages[index]

                return (
                  <div
                    className={cn(
                      CardGridBlockStyles[index],
                      cardGridBlockCommon
                    )}
                    key={`heroImageCard-${index}`}
                  >
                    <div className="inline-block">
                      <CircleImage />
                    </div>
                    <div className="text-left ml-4">
                      <Body variant="hero-content-small-label">
                        <span className={cn(showMobileHideDesktop)}>
                          {card.mobileHeadline}
                        </span>
                        <span className={cn(hideMobileShowDesktop)}>
                          {card.headline}
                        </span>
                      </Body>
                      <Body variant="body-small" className="mt-1">
                        <span className={cn(hideMobileShowDesktop)}>
                          {card.copy}
                        </span>
                      </Body>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </Gutter>
      </div>
      <SlantedTriangle />
    </div>
  )
}
