import React from 'react'

const Phone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
    <path
      fill="#177c8f"
      d="M3 .75C1.292.75.8 1.401.113 2.703a.988.988 0 0 0-.112.465C.13 17.425 11.285 21 17.25 21c.718 0 2.259-.53 2.916-2.602a.918.918 0 0 0-.015-.576l-.479-1.387a1 1 0 0 0-.68-.637L14.8 14.643c-.34-.094-.704 0-.972.229-.385.33-.963.782-1.437.974-.24.097-.535.174-.77.066-1.2-.553-4.554-4.226-6.622-6.625-.296-.343-.313-.837.002-1.162a22.19 22.19 0 0 1 1.284-1.193c.284-.246.415-.626.327-.991-.238-.993-.751-3.004-1.278-4.273-.053-.13-.075-.278-.163-.387C4.977 1.039 4.393.75 3 .75Z"
    />
  </svg>
)

export default Phone
