import React from 'react'

import A from './Type/A'
import Body from './Type/Body'
import Heading from './Type/Heading'

interface ErrorComponentProps {
  headline: string
}

interface ErrorLinkProps {
  text: string
  url: string
}

interface ErrorProps extends ErrorComponentProps {
  link: ErrorLinkProps
}

const errorBorderStyle = `
  border-primary
  border-2
  rounded-lg
  max-w-3xl
  mx-auto
  my-12
  py-8
  text-center
`

const bodyStyle = `
  pt-6 
  pb-8 
  mx-4 
  lg:mx-20
`

const backButtonWrapperStyle = `
  mx-4
`

const backButtonStyle = `
  w-full
  lg:w-auto
  inline-block
`

export default function ErrorPage({ headline, link }: ErrorProps) {
  return (
    <div className="mx-6">
      <div className={errorBorderStyle}>
        <Heading className="" as="h1" variant="h2">
          {headline}
        </Heading>

        <main>
          <Body className={bodyStyle} variant="body-small">
            We’re sorry, the server encountered an error and could not complete
            your request. You can still complete enrollment by calling:&nbsp;
            <A variant="default-black" url="tel:844-693-4889">
              844-693-4889
            </A>
            .
            <span className="pt-6 block">
              You can also try again later, or contact&nbsp;
              <A variant="inline" url="mailto:support@divvydose.com">
                support@divvydose.com
              </A>
            </span>
          </Body>
        </main>

        <div className={backButtonWrapperStyle}>
          <A variant="button" url={link.url} className={backButtonStyle}>
            {link.text}
          </A>
        </div>
      </div>
    </div>
  )
}
