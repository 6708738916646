import React from 'react'

export default function SlantedTriangle() {
  return (
    <div className="heroSpacer-wrapper bg-mono-mint">
      <div className="heroSpacer-innerWrapper">
        <svg
          className="heroSpacer-svg"
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
        >
          <polygon points="100,100 100,0 0,100" opacity="1"></polygon>
        </svg>
      </div>
    </div>
  )
}
