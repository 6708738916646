export const data = [
  {
    text: 'How it Works',
    url: 'https://divvydose.com/how-it-works/'
  },
  {
    text: 'For Caregivers',
    url: 'https://divvydose.com/caregivers/'
  },
  {
    text: 'Healthcare Providers',
    url: 'https://divvydose.com/healthcare-providers/'
  },
  {
    text: 'Insurance',
    url: 'https://divvydose.com/pricing/'
  },
  {
    text: 'Cost = Zero',
    url: 'https://divvydose.com/pricing/'
  },
  {
    text: 'Terms of Use',
    url: 'https://divvydose.com/terms-of-use/'
  },
  {
    text: 'Privacy Policy',
    url: 'https://divvydose.com/privacy-policy/'
  },
  {
    text: 'Contact Us',
    url: 'https://divvydose.com/contact-us/'
  }
]

export const logoData = {
  text: 'NABP Badge',
  url: 'https://app.us.openbadges.me/view/#!/badge?badgeid=497b960f-c9fd-41f5-b4c5-679650a64962'
}
