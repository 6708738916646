import React from 'react'
import cn from 'classnames'

import A from '../Type/A'
import Body from '../Type/Body'
import XClose from './images/XClose'
import Gutter from '../Gutter'
import './mobilePhoneBanner.css'

interface MobilePhoneBannerProps {
  phoneMobile: 'active' | 'inactive'
  setPhoneMobile: Function
}

const bannerStyles = `
  lg:hidden
  w-full
  bg-mono-mint
  topShadow
  fixed
  bottom-0
  right-0
  z-10
`

const bannerActive = {
  active: 'fixed',
  inactive: 'hidden'
}

export default function MobilePhoneBanner({
  phoneMobile,
  setPhoneMobile
}: MobilePhoneBannerProps) {
  return (
    <div className={cn(bannerStyles, bannerActive[phoneMobile])}>
      <div className="py-2.5">
        <Gutter>
          <div className="relative h-16">
            <div className="pr-8 h-full table">
              <Body
                className="inline-block align-middle table-cell"
                variant="body-small"
              >
                Questions? Prefer to enroll over the phone?{' '}
                <span className="text-base font-bold text primary">Call</span>
                &nbsp;
                <A
                  className="font-bold"
                  variant="default-black"
                  url="tel:844-693-4889"
                >
                  844-693-4889
                </A>
                .
              </Body>
            </div>

            <button
              className="absolute top-0 right-0"
              onClick={() => setPhoneMobile('inactive')}
            >
              <XClose></XClose>
            </button>
          </div>
        </Gutter>
      </div>
    </div>
  )
}
