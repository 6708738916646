import React from 'react'

export default function BoxIcon() {
  return (
    <svg
      className="m-auto"
      width="80"
      height="80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M53.642 53 40 58.746v8.499L53.642 61v-8Z" fill="#196ECF" />
      <path
        d="M40.039 72.575a1.115 1.115 0 0 1-.416-.102L9.387 58.077a1.013 1.013 0 0 1-.572-.91V41.631l-8.24-3.888a1.002 1.002 0 0 1-.532-.612 1.016 1.016 0 0 1 .101-.813l8.502-14.167L40.03 7 71.4 22.225l8.458 14.22c.146.242.181.535.094.807-.084.273-.28.499-.54.62l-8.177 3.774V57.62c0 .391-.229.75-.583.914L40.485 72.481c-.129.06-.265.092-.404.094h-.042Zm1.03-2.582 28.157-13.017v-14.4l-19.914 9.193c-.136.062-.28.094-.421.094-.347 0-.677-.186-.858-.48L41.07 39.995v29.997ZM10.826 56.532 39.06 69.976V39.825l-7.509 11.58c-.188.287-.503.46-.842.46a.984.984 0 0 1-.426-.096l-19.46-9.186v13.949h.002Zm-8.386-20.13 7.583 3.579.057.017a.97.97 0 0 1 .268.127l.046.03.038.02 19.911 9.399 8.255-12.728L10.213 23.45 2.439 36.403Zm39.072.459 7.771 12.705 28.28-13.052-7.724-12.985L41.51 36.86ZM12.185 22.158l27.879 13.157L67.873 22.23 40.063 9.136 12.186 22.158Z"
        fill="#177C8F"
      />
    </svg>
  )
}
