export const HeroData = {
  headline: 'Enter your insurance info below',
  mobileHeadline: 'Enter Insurance Information',
  copy: 'You’ll need your insurance card to complete the fields below. We’ll use this information to calculate your copays',
  mobileCopy:
    'You’ll need your insurance card. We’ll use this information to calculate your copays.'
}

export const FormData = {
  midFormCopy:
    'Rx PCN and Rx BIN numbers may be found on a separate pharmacy benefit card.',
  submitButtonCopy: 'Submit & Continue',
  skipForNowCopy: 'Skip for Now'
}

export const InsuranceTooltipCopies = {
  memberId:
    'Your Member ID will typically be in the top half of your insurance card. It’s a 12-14 digit number, containing both numbers and characters. ',
  groupNumber:
    'Your Group ID will typically be on the top half of your insurance card. The Group number is different than the RxGRP (not requested).',
  pcn: 'You can typically locate your Rx PCN on the bottom half of your insurance card, or on your prescription benefit card.',
  bin: 'You can typically locate your Rx BIN on the bottom half of your insurance card, or on your prescription benefit card.'
}
