import divvyDOSEPharmacistDesktop from '../../../../images/divvyDOSEPharmacist.jpg'
import divvyDOSEPharmacistMobile from '../../../../images/divvyDOSEPharmacist-mobile.jpg'

import divvyDoseUnboxingDesktop from '../../../../images/divvyDOSEUnboxing.jpg'
import divvyDoseUnboxingMobile from '../../../../images/divvyDOSEUnboxing-mobile.jpg'

import { ListItemDetailVariant } from '../../../../components/CopyImageCardSection/types'
import { IconRowIconProps } from '../../../../components/IconRowSection/IconRowSectionTypes'

export const HeroData = {
  headline: 'Getting Started with divvyDOSE',
  copy: 'We make it easy to enroll and customize your medication routine',
  mobileHeadline: 'Get Started with divvyDOSE',
  mobileCopy: 'We make it easy to enroll & customize your medicine routine',
  cards: [
    {
      headline: 'You provide contact & insurance information',
      copy: 'Make sure you have your insurance card on-hand.',
      mobileHeadline: 'You provide contact information'
    },
    {
      headline: "We'll call you back within 2 business days",
      copy: "We'll collect additional information to confirm copays.",
      mobileHeadline: "We'll call you within 2 business days"
    },
    {
      headline: 'We calculate your exact copays',
      copy: "We won't charge you until you know your copays.",
      mobileHeadline: 'We calculate your exact copays'
    },
    {
      headline: 'You get your first box!',
      copy: 'Your box will arrive, delivered to your doorstep.',
      mobileHeadline: 'You get your first box!'
    }
  ]
}

export const FormHeroData = {
  headline: 'Begin by Providing Your Contact Information',
  copy: 'Your contact information will help us create an account and get you your first box',
  mobileHeadline: 'Provide Your Contact Info',
  mobileCopy: 'This will help us create an account '
}

export const IconRowSectionData = {
  headline: 'Set your personal medication routine',
  mobileHeadline: 'Customize your routine',
  video: '',
  cards: [
    {
      headline: 'Transfer & Manage Prescriptions',
      copy: 'We’ll transfer your prescriptions and handle your future refills.',
      mobileHeadline: 'Transfer & Manage Prescriptions',
      mobileCopy:
        'We’ll transfer your prescriptions and handle your future refills.',
      icon: IconRowIconProps.PLUS
    },
    {
      headline: 'Delivery to Your Doorstep',
      copy: 'We deliver your personalized box, once a month.',
      mobileHeadline: 'Delivery  to Your Doorstep',
      mobileCopy: 'We deliver your personalized box, once a month.',
      icon: IconRowIconProps.BOX
    },
    {
      headline: 'Our Service is Free!',
      copy: 'Delivery is free, you only pay your co-pays. We work with most insurance companies.',
      mobileHeadline: 'Our Service is Free!',
      mobileCopy:
        'Delivery is free, you only pay your co-pays. We work with most insurance companies.',
      icon: IconRowIconProps.MONEY
    }
  ]
}

export const CopyImageCardOne = {
  headline: 'What’s in Your Delivery?',
  copy: 'You’ll receive a discreet package, shipped anywhere in the USA. Your personalized box can hold:',
  backgroundColor: '#FAFAFA',
  desktopImage: {
    imageUrl: divvyDoseUnboxingDesktop,
    alt: 'Opening a divvyDOSE package'
  },
  mobileImage: {
    imageUrl: divvyDoseUnboxingMobile,
    alt: 'Opening a divvyDOSE package'
  },
  listItems: [
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Pre-sorted pill pouches'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Inhalers'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Ointments'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Vitamins & Supplements'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Prescriptions'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: "Any pills that can't be in pouches will come in traditional pill bottles"
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Medications that must remain cold will be shipped in temperature-safe packaging'
      }
    ]
  ]
}

export const CopyImageCardTwo = {
  headline: '24-hour Access',
  desktopImage: {
    imageUrl: divvyDOSEPharmacistDesktop,
    alt: 'divvyDOSE Pharmacist on the phone'
  },
  mobileImage: {
    imageUrl: divvyDOSEPharmacistMobile,
    alt: 'divvyDOSE Pharmacist on the phone'
  },
  backgroundColor: '#F6FCFF',
  listItems: [
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Call a pharmacist or trained expert whenever you need us: '
      },
      {
        variant: ListItemDetailVariant.BOLD,
        text: '844-693-4889'
      }
    ],
    [
      {
        variant: ListItemDetailVariant.DEFAULT,
        text: 'Our team will also reach out to you if we run into a problem, so we can quickly resolve it with you and keep you informed'
      }
    ]
  ]
}
