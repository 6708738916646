import React from 'react'
import Body from './Type/Body'

export default function DataProtectionStatement() {
  return (
    <section className="block" aria-label="Data Protection Statement">
      <svg
        className="inline-block mr-2"
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.43 4.637a.338.338 0 0 0-.315-.343c-3.665-.107-6.71-1.557-8.803-4.201C10.27.036 10.17 0 10.07 0c-.107 0-.2.036-.25.1C7.725 2.772 4.81 4.187 1.152 4.294c-.165 0-.3.158-.3.343 0 3.587-.408 7.389 2.615 10.804 1.972 2.151 4.166 3.68 6.502 4.545.014.007.043.014.115.014 1.236 0 4.694-2.35 6.73-4.573 3.016-3.401 2.616-7.203 2.616-10.79Zm-4.245 3.266L9.47 13.619a1.07 1.07 0 0 1-1.515 0l-2.143-2.143A1.07 1.07 0 1 1 7.326 9.96l1.386 1.386 4.959-4.959a1.07 1.07 0 0 1 1.514 0c.415.414.415 1.1 0 1.515Z"
          fill="#177C8F"
        />
      </svg>

      <Body variant="body-small" className="inline-block">
        We protect & encrypt your data
      </Body>
    </section>
  )
}
