import React from 'react'
import cn from 'classnames'

import Gutter from './Gutter'
import Heading from './Type/Heading'
import Body from './Type/Body'

export interface HeroCopyProps {
  headline: string
  copy: string
  mobileHeadline: string
  mobileCopy?: string
}

const hideMobileShowDesktop = `
  hidden
  lg:block
`
const showMobileHideDesktop = `
  block
  lg:hidden
`

export default function FormHeroSection({
  headline,
  copy,
  mobileHeadline,
  mobileCopy
}: HeroCopyProps) {
  return (
    <section aria-labelledby="formHeroHeadline">
      <Gutter>
        <div className="text-center mt-0 lg:mt-20">
          <Heading as="h2" variant="h1">
            <div id="formHeroHeadline">
              <span className={cn(showMobileHideDesktop)}>
                {mobileHeadline}
              </span>
              <span className={cn(hideMobileShowDesktop)}>{headline}</span>
            </div>
          </Heading>
          <Body className="text-xl lg:p-2" variant="helper">
            <span className={cn(showMobileHideDesktop)}>{mobileCopy}</span>
            <span className={cn(hideMobileShowDesktop)}>{copy}</span>
          </Body>
        </div>
      </Gutter>
    </section>
  )
}
