import React, { ReactNode } from 'react'

export interface ImageProps {
  imageUrl: string
  alt: string
}

export enum ListItemDetailVariant {
  DEFAULT = 'default',
  BOLD = 'bold',
  LINK = 'link'
}

export interface ListItemDetailProp {
  variant: ListItemDetailVariant
  text: string
  url?: string
}

export interface CopyImageCardSectionProps {
  headline: string
  copy?: string
  backgroundColor: string
  mobileImage: ImageProps
  desktopImage: ImageProps
  listItems: ListItemDetailProp[][]
}
