import React from 'react'
import { StepIconProps } from '@mui/material/StepIcon'

import PastStep from './PastStep'
import ActiveStep from './ActiveStep'
import FutureStep from './FutureStep'

import './styles.css'

const StepIcon = ({ active, completed }: StepIconProps) => {
  let Icon = FutureStep

  if (active === true) {
    Icon = ActiveStep
  }

  if (completed === true) {
    Icon = PastStep
  }

  return (
    <div className="h-5 w-5 stepIconWrapper">
      <Icon />
    </div>
  )
}

export default StepIcon
