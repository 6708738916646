import React from 'react'

export enum PageScreen {
  CONTACT = 'contactScreen',
  INSURANCE = 'insuranceScreen',
  SUMMARY = 'summaryScreen'
}

export interface ContactProps {
  firstName: string
  lastName: string
  phoneNumber: string
  dateOfBirth: Date | null
  email: string
}

export interface InsuranceProps {
  providerName: string
  idNumber: string
  groupNumber: string
  rxPCN: string
  rxBIN: string
}

export interface PromoProps {
  promoCode: string
}

export interface StepProps {
  formScreen: PageScreen
  copy: string
  mobileCopy: string
}

export interface FormScreenProps {
  pageScreen: PageScreen
  setPageScreen: Function
  phoneMobile: string
  setPhoneMobile: Function
  contact: ContactProps
  setContact: Function
  insurance: InsuranceProps
  setInsurance: Function
  promoCode: string
  setPromoCode: Function
  stepperData: StepProps[]
}
