import React from 'react'

export const FieldWrapperStyles = `
  py-5
`

// width
// Contact - 400
// Insurance - 400 + 8 + 20 + 24*2
// Summary - 608 + 24*2

// Contact
// 50 30
// formhero
// 45 32
// ? 24
// 56 48

// Insurance
// 40 48
// ? 24
// 56 48

// Summary
// 42 42 | 42 42
// ? 24 | 27 17
// 54 40 | 40 40
