import React from 'react'
import cn from 'classnames'

import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'

import {
  StyledDesktopConnector,
  StyledDesktopStepLabel,
  hideMobileShowDesktop
} from './styles'
import StepIcon from './Icons/StepIcon'

import { StepperProps } from './types'

export default function DesktopFormStepper({
  pageScreen,
  stepperData
}: StepperProps) {
  const activeStep = stepperData.findIndex(
    (step) => step.formScreen === pageScreen
  )

  return (
    <aside className="relative" aria-label="Form Stepper, Desktop">
      <div
        className={cn(
          hideMobileShowDesktop,
          'absolute top-16 left-desktopGutter'
        )}
      >
        <Stepper
          activeStep={activeStep}
          connector={<StyledDesktopConnector />}
          orientation="vertical"
        >
          {stepperData.map((step, index) => (
            <Step key={`step-desktop-${index}-${step.copy}`}>
              <StyledDesktopStepLabel StepIconComponent={StepIcon}>
                {step.copy}
              </StyledDesktopStepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </aside>
  )
}
