import React, { useState, useEffect } from 'react'

import ContactScreen from './ContactScreen'
import InsuranceScreen from './InsuranceScreen'
import SummaryScreen from './SummaryScreen'
import Footer from '../../../components/Footer'
import MobilePhoneBanner from '../../../components/MobilePhoneBanner/MobilePhoneBanner'

import { stepperData } from './data'

import { ContactProps, InsuranceProps, PageScreen } from './FormScreenTypes'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export default function FormScreens() {
  const [pageScreen, setPageScreen] = useState<PageScreen>(PageScreen.CONTACT)

  const [phoneMobile, setPhoneMobile] = useState<'active' | 'inactive'>(
    'active'
  )

  const [contact, setContact] = useState<ContactProps>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: null,
    email: ''
  })

  const [insurance, setInsurance] = useState<InsuranceProps>({
    providerName: '',
    idNumber: '',
    groupNumber: '',
    rxPCN: '',
    rxBIN: ''
  })

  const [promoCode, setPromoCode] = useState<string>('')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setPromoCode(params.get('promoCode') || '')
  }, [])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
    >
      {pageScreen === PageScreen.CONTACT ? (
        <ContactScreen
          pageScreen={pageScreen}
          setPageScreen={setPageScreen}
          phoneMobile={phoneMobile}
          setPhoneMobile={setPhoneMobile}
          contact={contact}
          setContact={setContact}
          insurance={insurance}
          setInsurance={setInsurance}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          stepperData={stepperData}
        />
      ) : pageScreen === PageScreen.INSURANCE ? (
        <InsuranceScreen
          pageScreen={pageScreen}
          setPageScreen={setPageScreen}
          phoneMobile={phoneMobile}
          setPhoneMobile={setPhoneMobile}
          contact={contact}
          setContact={setContact}
          insurance={insurance}
          setInsurance={setInsurance}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          stepperData={stepperData}
        />
      ) : pageScreen === PageScreen.SUMMARY ? (
        <SummaryScreen
          pageScreen={pageScreen}
          setPageScreen={setPageScreen}
          phoneMobile={phoneMobile}
          setPhoneMobile={setPhoneMobile}
          contact={contact}
          setContact={setContact}
          insurance={insurance}
          setInsurance={setInsurance}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          stepperData={stepperData}
        />
      ) : null}
      <MobilePhoneBanner
        phoneMobile={phoneMobile}
        setPhoneMobile={setPhoneMobile}
      />
      <Footer phoneMobile={phoneMobile} />
    </GoogleReCaptchaProvider>
  )
}
