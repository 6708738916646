import React from 'react'
import cn from 'classnames'

import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'

import {
  StyledMobileConnector,
  StyledMobileStepLabel,
  showMobileHideDesktop
} from './styles'
import StepIcon from './Icons/StepIcon'

import { StepperProps } from './types'

export default function MobileFormStepper({
  pageScreen,
  stepperData
}: StepperProps) {
  const activeStep = stepperData.findIndex(
    (step) => step.formScreen === pageScreen
  )

  return (
    <aside
      className={cn(showMobileHideDesktop, 'pt-4')}
      aria-label="Form Stepper, Mobile"
    >
      <Stepper
        activeStep={activeStep}
        connector={<StyledMobileConnector />}
        alternativeLabel
      >
        {stepperData.map((step, index) => (
          <Step key={`step-mobile-${index}-${step.mobileCopy}`}>
            <StyledMobileStepLabel StepIconComponent={StepIcon}>
              {step.mobileCopy}
            </StyledMobileStepLabel>
          </Step>
        ))}
      </Stepper>
    </aside>
  )
}
