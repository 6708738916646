import React from 'react'

export default function CircledNumberThree() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.501" r="12" fill="#196ECF" />
      <path
        d="M12.7672 11.5481C13.6259 11.6881 14.2839 12.0101 14.7412 12.5141C15.1985 13.0088 15.4272 13.6248 15.4272 14.3621C15.4272 14.9315 15.2779 15.4588 14.9792 15.9441C14.6805 16.4201 14.2232 16.8028 13.6072 17.0921C13.0005 17.3815 12.2539 17.5261 11.3672 17.5261C10.6765 17.5261 9.9952 17.4375 9.3232 17.2601C8.66054 17.0735 8.09587 16.8121 7.6292 16.4761L8.5112 14.7401C8.88454 15.0201 9.31387 15.2395 9.7992 15.3981C10.2939 15.5475 10.7979 15.6221 11.3112 15.6221C11.8805 15.6221 12.3285 15.5148 12.6552 15.3001C12.9819 15.0761 13.1452 14.7635 13.1452 14.3621C13.1452 13.5595 12.5339 13.1581 11.3112 13.1581H10.2752V11.6601L12.2912 9.37812H8.1192V7.55812H14.9792V9.02812L12.7672 11.5481Z"
        fill="white"
      />
    </svg>
  )
}
