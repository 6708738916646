import React from 'react'

import HeroSection from '../../../components/Hero'
import CopyImageCardSection from '../../../components/CopyImageCardSection'

import { SuccessHeroData, SummaryCopyImageCardSectionData } from './data'
import Footer from '../../../components/Footer'
import ScrollToTop from '../../../components/ScrollToTop'

export default function SuccessPage() {
  return (
    <div>
      <main>
        <ScrollToTop />
        <HeroSection {...SuccessHeroData} />
      </main>

      <CopyImageCardSection {...SummaryCopyImageCardSectionData} />
      <Footer />
    </div>
  )
}
