import React, { MouseEvent, ReactNode } from 'react'
import cn from 'classnames'

const ButtonVariant = {
  default: `
    px-10 rounded-full border
    text-mono-white bg-primary border-primary
    py-3 text-lg font-bold text-center
  `,
  white: `
    underline
    text-primary bg-white border-mono-white
    py-3 text-lg font-bold text-center
  `
}

type ButtonVariantTypes = keyof typeof ButtonVariant
export interface ButtonProps {
  children: ReactNode
  className?: string
  variant?: ButtonVariantTypes
  type?: 'button' | 'reset' | 'submit'
  isDisabled?: boolean
  handleClick: (e: MouseEvent<HTMLButtonElement>) => void
  title: string
  dataID: string
}

export default function Button({
  children,
  className,
  variant = 'default',
  type = 'button',
  handleClick,
  isDisabled,
  title,
  dataID
}: ButtonProps) {
  return (
    <button
      aria-disabled={isDisabled}
      aria-label={title}
      className={cn(ButtonVariant[variant], className)}
      disabled={isDisabled}
      onClick={handleClick}
      title={title}
      type={type}
      id={dataID}
    >
      {children}
    </button>
  )
}
