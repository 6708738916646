export const data = [
  {
    text: 'Home',
    url: 'https://divvydose.com/'
  },
  {
    text: 'How it Works',
    url: 'https://divvydose.com/how-it-works/'
  },
  {
    text: 'Pricing',
    url: 'https://divvydose.com/pricing/'
  },
  {
    text: 'Caregivers',
    url: 'https://divvydose.com/caregivers/'
  }
]

export const subListNavData = [
  {
    text: 'The Pharmacy',
    url: 'https://divvydose.com/pharmacy/'
  },
  {
    text: 'Healthcare Providers',
    url: 'https://divvydose.com/healthcare-providers/'
  },
  {
    text: 'Contact Us',
    url: 'https://divvydose.com/contact-us/'
  }
]

export const phoneData = {
  text: '844-693-4889',
  url: 'tel:8446934889'
}
