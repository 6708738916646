import React from 'react'
import cn from 'classnames'

import Gutter from '../Gutter'
import Heading from '../Type/Heading'
import A from '../Type/A'
import Body from '../Type/Body'

import { CopyImageCardSectionProps, ListItemDetailVariant } from './types'

import './styles.css'

const GridWrapperStyles = `
  grid
  gap-5

  items-center
  grid-cols-1
  lg:grid-cols-2
`

const CopyCardWrapperStyles = `
  py-8
`

const CopyStyles = `
  pt-4
`

const BulkyListItemStyles = `
  py-3
`
const LeanListItemStyles = `
  pt-3
`

const ListStyles = `
  pt-2

  list-disc
  list-inside
`
const ListItemStyles = {
  bulky: BulkyListItemStyles,
  lean: LeanListItemStyles
}

const DesktopImageStyles = `
  w-full
  h-full
  bg-no-repeat
  bg-cover
  bg-center
`
const MobileImageStyles = `
  w-screen
`

const hideMobileShowDesktop = `
  hidden
  lg:block
`
const showMobileHideDesktop = `
  block
  lg:hidden
`

export default function CopyImageCardSection({
  headline,
  copy,
  desktopImage,
  mobileImage,
  backgroundColor,
  listItems
}: CopyImageCardSectionProps) {
  return (
    <section
      className={GridWrapperStyles}
      style={{ backgroundColor }}
      aria-label={headline}
    >
      <Gutter>
        <div className={CopyCardWrapperStyles}>
          <Heading as="h2" variant="h2">
            {headline}
          </Heading>
          <div>
            {copy ? <Body className={CopyStyles}>{copy}</Body> : null}
            <ul className={ListStyles}>
              {listItems.length
                ? listItems.map((listItem, index) => {
                    if (listItem.length) {
                      const listItemStyle =
                        listItems.length > 5
                          ? ListItemStyles.lean
                          : ListItemStyles.bulky

                      return (
                        <li
                          key={`listItem-${index}-${headline}`}
                          className={cn(listItemStyle)}
                        >
                          <Body variant="body-small" className="inline">
                            {listItem.map((item, itemIndex) => {
                              switch (item.variant) {
                                case ListItemDetailVariant.BOLD:
                                  return (
                                    <span
                                      className="font-semibold"
                                      key={`listItemDetail-${index}-${itemIndex}-${headline}`}
                                      dangerouslySetInnerHTML={{
                                        __html: item.text
                                      }}
                                    />
                                  )
                                case ListItemDetailVariant.LINK:
                                  return (
                                    <A
                                      url={
                                        item.url
                                          ? item.url
                                          : 'https://divvydose.com/'
                                      }
                                      key={`listItemDetail-${index}-${itemIndex}-${headline}`}
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: item.text
                                        }}
                                      />
                                    </A>
                                  )
                                default:
                                  return (
                                    <span
                                      key={`listItemDetail-${index}-${itemIndex}-${headline}`}
                                      dangerouslySetInnerHTML={{
                                        __html: item.text
                                      }}
                                    />
                                  )
                              }
                            })}
                          </Body>
                        </li>
                      )
                    }
                  })
                : null}
            </ul>
          </div>
        </div>
      </Gutter>
      <div
        className={cn(
          'desktopImageCard',
          hideMobileShowDesktop,
          DesktopImageStyles
        )}
        style={{ backgroundImage: `url("${desktopImage.imageUrl}")` }}
        title={desktopImage.alt}
      />
      <img
        className={cn(
          'mobileImageCard',
          showMobileHideDesktop,
          MobileImageStyles
        )}
        src={mobileImage.imageUrl}
        alt={mobileImage.alt}
      />
    </section>
  )
}
