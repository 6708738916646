import React from 'react'

export default function MoneyIcon() {
  return (
    <svg
      className="m-auto"
      width="80"
      height="80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="26.75" stroke="#177C8F" strokeWidth="2.5" />
      <path
        d="M47.7 45.222c0 1.813-.509 3.386-1.527 4.718-1.001 1.31-2.46 2.152-4.378 2.523V56.1H38.74v-3.506a13.16 13.16 0 0 1-3.614-.82c-1.137-.458-2.08-1.015-2.826-1.67l1.655-4.784a9.806 9.806 0 0 0 2.774 1.638c1.052.415 2.087.623 3.106.623 1.934 0 2.901-.623 2.901-1.868 0-.655-.28-1.136-.84-1.441-.543-.328-1.425-.667-2.647-1.016-1.34-.372-2.46-.765-3.36-1.18-.9-.437-1.671-1.125-2.316-2.064-.645-.94-.968-2.206-.968-3.8 0-1.88.527-3.485 1.579-4.818 1.052-1.354 2.57-2.184 4.556-2.49V25.3h3.055v3.539c.967.109 1.892.327 2.774.655.9.328 1.697.754 2.393 1.278l-1.553 4.817c-1.697-1.18-3.343-1.77-4.938-1.77-1.001 0-1.731.197-2.19.59-.457.371-.686.863-.686 1.474 0 .612.271 1.07.814 1.377.543.305 1.417.622 2.622.95 1.358.371 2.477.775 3.36 1.212.9.415 1.672 1.092 2.316 2.032.662.917.993 2.173.993 3.768Z"
        fill="#196ECF"
      />
    </svg>
  )
}
