import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import React, { ReactNode } from 'react'
import { SvgIcon } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Button from '@mui/material/Button'
import { ReactComponent as MoreInfoIcon } from '../../images/moreInfoIcon.svg'
import cn from 'classnames'
import Body from '../Type/Body'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F6FCFF',
    color: 'rgba(0, 0, 0, 1)',
    maxWidth: 300,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '22px',
    borderRadius: '8px',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25);'
  }
}))

interface HTMLToolTipProps {
  children: ReactNode
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

function HtmlToolTip({ children, placement = 'bottom' }: HTMLToolTipProps) {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleTooltipOpen = () => {
    setOpen(true)
  }
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledTooltip
          title={<React.Fragment>{children}</React.Fragment>}
          placement={placement}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          arrow
        >
          <Button onClick={handleTooltipOpen}>
            <SvgIcon component={MoreInfoIcon} />
          </Button>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  )
}

interface FieldTooltipProps {
  copy: string
  title: string
  classnames?: string
}

export default function FieldTooltip({
  copy,
  title,
  classnames
}: FieldTooltipProps) {
  const additionalClassNames = classnames ? classnames : ''

  return (
    <div title={title} className={cn('ml-2', additionalClassNames)}>
      <HtmlToolTip placement="bottom">
        <Body variant="body" className="px-3 my-4">
          {copy}
        </Body>
      </HtmlToolTip>
    </div>
  )
}
