import React, { ReactNode } from 'react'
import cn from 'classnames'

const HeadingVariants = {
  h1: `text-2xl lg:text-4xl leading-10 font-medium font-serif text-primary`,
  h2: `text-2xl lg:text-3xl leading-9 font-medium font-serif text-primary`,
  h3: `text-2xl leading-7 font-semibold font-sans text-mono-700`,
  h4: `text-xl leading-8 font-semibold font-sans text-mono-700`,
  h5: `text-lg lg:text-xl leading-6 lg:leading-7 font-normal font-sans text-mono-700`
}

type HeadingVariantType = keyof typeof HeadingVariants

export interface HeadingProps {
  children: ReactNode
  as: HeadingVariantType
  variant: HeadingVariantType
  className?: string
}

const Heading = ({ as: As, children, variant, className }: HeadingProps) => (
  <As className={cn(HeadingVariants[variant], className)}>{children}</As>
)
export default Heading
